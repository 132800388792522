<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div v-if="hasBlock && hasBlock.isActive" class="page-message" :style="{'background-color': hasBlock.colour}">
            <span>
              {{ hasBlock.message }}
            </span>
          </div>
          <div class="page-title-inner">
            <router-link v-if="showBtn" to="/public/new-order" class="button button--fill button--fly">
              <i class="ri-map-pin-add-fill"></i>
              Book Order
            </router-link>
            <div class="orders__nav-list">
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === null}"
                @click="filterOrders(null)"
              >
                Recent
              </div>
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === 0}"
                @click="filterOrders(0)"
              >
                Pending
              </div>
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === 3}"
                @click="filterOrders(3)"
              >
                Declined
              </div>
              <div
                class="orders__nav-item"
                :class="{'orders__nav-item--active': status === 2}"
                @click="filterOrders(2)"
              >
                Paid
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-main">
        <div class="container">
          <Order v-for="order of orders" :key="order.id" :order="order" @click.native="openOrder(order)" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Order from '../Order'
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'ClientDeliveries',
  components: {Order, InfiniteLoading},
  data() {
    return {
      isLoading: false,
      status: null,
      moment
    }
  },
  computed: {
    ...mapState({
      hasBlock: s => s.operationStatus.hasBlock
    }),
    showBtn() {
      if (!this.hasBlock || this.hasBlock?.bypassed) {
        return true
      } else {
        return false
      }
    },
    orders() {
      return this.$store.getters['orders/orders']
    },
    hasMoreResults() {
      return this.orders.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['orders/pagination'].total
    },
    page() {
      return this.$store.getters['orders/page']
    }
  },
  async created() {
    this.layout = this.$route.meta.layout
    await Promise.all([this.$store.dispatch('orders/fetch'), this.$store.dispatch('orders/fetchStatuses')])
  },
  methods: {
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('orders/fetch', {'filters[status][]': this.status})
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    async filterOrders(status) {
      this.status = status
      this.$store.commit('orders/reset')
      await this.$store.dispatch('orders/fetch', {'filters[status][]': status})
    },
    openOrder(order) {
      this.$router.push('/public/orders/' + order.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
